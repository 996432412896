import React from "react";

function article_1() {
  return {
    date: "10 May 2023",
    title: "Urban Growth Analysis of Vila-real: A Geospatial Study.",
    description:
      "A GIS analysis of the urban growth of Vila-real, highlighting the spatial changes over decades and suitability for future urban development.",
    keywords: [
      "Urban Growth Analysis",
      "GIS in Urban Planning",
      "Geospatial Analysis",
      "UNIGIS Master Final Project",
      "Vila-real Urban Development",
    ],
    style: `
      .article-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: Arial, sans-serif;
        line-height: 1.6;
      }

      .randImage {
        align-self: center;
        outline: 2px solid red;
        margin-top: 20px;
      }

      .paragraph {
        text-align: justify;
        margin: 20px;
      }

      .iframe-wrapper {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%; /* 16:9 aspect ratio */
        height: 0;
        margin: 20px 0;
      }

      .iframe-wrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }
    `,
    body: (
      <React.Fragment>
        <div className="article-content">
          <div className="paragraph">
            As part of my final project for the Professional Master’s in GIS: Specialization in Geospatial
            Analysis at UNIGIS, I conducted an extensive study on the urban growth of Vila-real, a
            municipality in Spain. This project aimed to analyze Vila-real's urban expansion from 1987 to
            2019 and identify areas suitable for future urban development through geospatial analysis.
          </div>
          <div className="paragraph">
            Key aspects of the study included the use of satellite imagery to evaluate land cover changes
            and applying a multicriteria analysis that integrated factors like land cover, proximity to
            infrastructure, topography, and flood risk. Tools such as <b>QGIS</b> with plugins like the
            <b> Semi-Automatic Classification Plugin (SCP)</b> and VectorMCDA, were crucial to this work.
          </div>
          <div className="paragraph">
            The results revealed significant growth patterns in urban areas while emphasizing the need for
            sustainable urban planning to balance development with environmental conservation. Below is a
            map illustrating the findings of this study, highlighting, in one hand, a map showing the areas considered for the urban
			  expansion, classified for by aptitude index, and on the other hand, the final areas I have suggested for the urban expansion because of their maximum idoneity.
			  I have published the maps using the <b>ArcGIS Online platform</b> for easy access, making use of the <b>ArcGIS Instant Apps</b> feature.

          </div>
			<div className="iframe-wrapper">
				<iframe
					src="https://www.arcgis.com/apps/instant/interactivelegend/index.html?appid=68096e77ea2b45cd808fcf01657b112f"
					allowFullScreen
				>
					No se admiten iFrames en esta página.
				</iframe>
			</div>
			<div className="iframe-wrapper">
				<iframe
					src="https://www.arcgis.com/apps/instant/basic/index.html?appid=20e3b8164cf34233b15d183614c2b89f"
					allowFullScreen
				>
					No se admiten iFrames en esta página.
				</iframe>
			</div>
		</div>
	  </React.Fragment>
),
};
}

const myArticles = [article_1];

export default myArticles;