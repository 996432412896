const SEO = [
	{
	  page: "home",
	  description:
		"I am Daniel Trenado Gutierrez, a web developer based in Murcia, specializing in GIS technologies and building high-performance web applications. I help local businesses and individuals develop secure, scalable websites using modern technologies like React and Node.js.",
	  keywords: [
		"Daniel Trenado Gutierrez",
		"desarrollador web Murcia",
		"freelance en Murcia",
		"desarrollador React Murcia",
		"desarrollador GIS Murcia",
		"aplicaciones web Murcia",
		"desarrollador web seguro",
		"desarrollador frontend Murcia",
	  ],
	},
  
	{
	  page: "about",
	  description:
		"I'm Daniel Trenado Gutierrez, a web developer located in Murcia, with a focus on GIS technologies. I design and develop custom web applications tailored to your business needs, specializing in building scalable, secure, and reliable digital solutions.",
	  keywords: [
		"Daniel Trenado Gutierrez",
		"desarrollador web Murcia",
		"desarrollador GIS Murcia",
		"desarrollador React Murcia",
		"programador frontend Murcia",
		"software engineer Murcia",
		"desarrollador full-stack Murcia",
	  ],
	},
  
	{
	  page: "articles",
	  description:
		"Explore my articles on web development, GIS, and technology. I share my experiences as a developer based in Murcia, covering topics such as programming, leadership, and product design, with a focus on local tech trends.",
	  keywords: [
		"Daniel Trenado Gutierrez",
		"artículos de programación Murcia",
		"desarrollador web Murcia",
		"liderazgo en tecnología",
		"diseño de productos Murcia",
		"blog de desarrollo web",
		"tendencias tecnológicas Murcia",
	  ],
	},
  
	{
	  page: "projects",
	  description:
		"Discover the projects I’ve worked on, including GIS-based web applications and custom solutions for businesses in Murcia. Many of my projects are open-source and I welcome contributions from developers worldwide.",
	  keywords: [
		"Daniel Trenado Gutierrez",
		"proyectos web Murcia",
		"proyectos GIS Murcia",
		"desarrollador open-source Murcia",
		"proyectos React Murcia",
		"tecnología geoespacial Murcia",
		"colaboración open-source Murcia",
	  ],
	},
  
	{
	  page: "contact",
	  description:
		"Let’s connect! Whether you need a web developer in Murcia or you have an exciting project in GIS, I’m open to collaboration opportunities. Contact me to discuss how we can work together to create innovative digital solutions.",
	  keywords: [
		"Daniel Trenado Gutierrez",
		"contactar desarrollador web Murcia",
		"colaboración proyectos Murcia",
		"desarrollador GIS Murcia",
		"freelance web Murcia",
		"contacto desarrollador Murcia",
	  ],
	},
  ];
  
  export default SEO;
  